.App {
  text-align: center;
  max-width: 1100px;
  margin: auto;
  

}

.outerApp {
  font-family: 'Nunito', sans-serif;
  color: white;
}

body {
  /*background-color: #555A64;*/
  background-color: #2F2F2F;

}

hr {
  margin: 0.75rem 0;
  height: 0.03rem !important;
}

.minimalistic-nav-bar {
  display: flex;
  background-color: #212529;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: .2rem;
  padding-right: .2rem;
  justify-content: space-between;
}

.minimalistic-nav-bar div:nth-of-type(2) {
  font-size: 20px;
  font-weight: 800;
  text-shadow: 1px 1px 2px black, 0 0 25px cyan, 0 0 5px darkcyan;
}

.minimalistic-nav-bar div:nth-of-type(3) {
  width: 300px;
  text-align: end;
  margin-right: .2rem;
  font-weight: 400;
  font-size: 16px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.inner-container {
  /*color: black;*/
  margin-top: 10px;
  color: #33D3D2;
  background-color: #212529;
  width: 38%;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 50%);
}

.button-stretch button {
  border-radius: 15px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  width: 90%;
}

.label-above-button {
  font-size: 12px;
  text-align: start;
  width: 90%;
  margin: auto;
  color: lightgrey;
  font-weight: 300;
  padding-left: 10px;
}

.btn:active {
  background-color: #0D4747 !important;
  border-color: #0D4747 !important;
}

.btn:hover, .btn:focus {
  background-color: #115151;
  border-color: #115151;
}

.btn:disabled {
  background-color: #156667 ;
  border-color: #156667 ;
}

.btn {
  background-color: #156667 ;
  border-color: #156667 ;
}


.main-buttons-div {
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
}

.main-buttons-div button {
  width: 40%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
}

button:focus , button:active {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%) !important;
}


.center-elements {
  display: flex;
  align-items: center;
  justify-content: center;
}


.input-button-container {
  display: flex;
  width: 90%;
  margin: auto;
}

.input-button-container div:nth-of-type(1) .form-control {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  /*text-shadow: 2px 2px 4px darkgrey;*/
}


.input-button-container div:nth-of-type(1) {
  flex-grow: 4;
}

.input-button-container div:nth-of-type(1) .form-control:focus {
  border-color: #ced4da;
  /*box-shadow: none;*/
}

.input-button-container div:nth-of-type(2) {
  margin-left: auto;
}

.input-button-container div:nth-of-type(2) .btn {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
}

.time-left-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*width: 86%;*/
  margin: auto;
  margin-left: 5%;
  margin-right: 1%;
}

.time {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.time >div {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.card-key-value {
  display: flex;
  width: 90%;
  margin: auto;
}

.card-key-value >div:nth-of-type(2) {
  margin-left: auto;
}

.two-line-label {
  width: 90%;
  margin: auto;
}


.two-line-label >div:nth-of-type(2) {
  font-size: 14px;
  font-weight: 400;
}

.input-button-container >.form-control:focus {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
}

.tooltip-inner {
  background-color: #0F4949;
  border-radius: 15px;
}

.tooltip-arrow::before {
  border-right-color: #0F4949 !important;
}